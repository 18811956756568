import Vue from 'vue'
import Vuex from 'vuex'
import App from './App'
import router from './router'
import moment from "moment"
import { $config } from "@/config";

Vue.use(Vuex)

Vue.config.productionTip = false

import axios from "axios";
const store = new Vuex.Store({
    state: {
        quiz: false,
        invite: false,
        result: false,
        question: false,
        questions: {},
        admin: false,
    },
    getters: {
        getResult: (state) => {
            return state.result;
        },
        quizScenario: (state) => (id) => {
            return state.quiz.scenarios.find(scenario => scenario.id === id);
        },
        quizQuestion: (state) => (id) => {
            console.log(state.result.question_index);
            return state.questions[id];
        },
    },
    actions: {
        GET_QUIZ({commit}, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get($config['url'] + "api/v1/quiz/" + id)
                    .then(function (response) {
                        commit('setQuiz', response.data.quiz);
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        if (error.response.status === 404)
                            window.location.href = '/error/404';
                        reject(error);
                    });
            })
        },
        GET_INVITE({commit}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .get($config['url'] + "api/v1/quiz/invite/" + data.id + "/" + data.key + '?timestamp=' + moment().unix())
                    .then(function (response) {
                        commit('setInvite', response.data.quizInvite);
                        commit('setQuiz', response.data.quiz);
                        if(typeof response.data.result != 'undefined')
                            commit('reloadQuiz', response.data)
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        if (error.response.status === 404)
                            window.location.href = '/error/404';
                        window.location.href = '/error/403';
                        reject(error);
                    });
            })
        },
        SAVE_INVITE({commit}, data) {
            return new Promise((resolve, reject) => {
                let form = new FormData();
                form.append("quiz_invite_id", data.id);
                form.append("quiz_invite_key", data.key);
                form.append("last_name", data.last_name);
                form.append("first_name", data.first_name);
                form.append("middle_name", data.middle_name);
                form.append("region", data.region);
                form.append("category", data.category);
                form.append("category_year", data.category_year);
                axios
                    .post($config['url'] + "api/v1/quiz/invite/" + data.id + "/" + data.key, form)
                    .then(function (response) {
                        commit('setInviteSave');
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        if (error.response.status === 404)
                            window.location.href = '/error/404';
                        window.location.href = '/error/403';
                        reject(error);
                    });
            })
        },
        START_QUIZ({commit}, quiz_scenario_id) {
            return new Promise((resolve, reject) => {
                let form = new FormData();
                form.append("quiz_scenario_id", quiz_scenario_id);
                form.append("timestamp", moment().unix());
                axios
                    .post($config['url'] + "api/v1/quiz/start", form)
                    .then(function (response) {
                        commit('startQuiz', response.data);
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        if (error.response.status === 404)
                            window.location.href = '/error/404';
                        window.location.href = '/error/403';
                        reject(error);
                    });
            })
        },
        START_INVITE({commit}, data) {
            return new Promise((resolve, reject) => {
                let form = new FormData();
                form.append("quiz_invite_id", data.id);
                form.append("quiz_invite_key", data.key);
                form.append("timestamp", moment().unix());
                axios
                    .post($config['url'] + "api/v1/quiz/invite/start", form)
                    .then(function (response) {
                        commit('startQuiz', response.data);
                        commit('countInvite');
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        if (error.response.status === 404)
                            window.location.href = '/error/404';
                        window.location.href = '/error/403';
                        reject(error);
                    });
            })
        },
        SEND_ANSWER({commit,state}, quiz_option_id) {
            return new Promise((resolve, reject) => {
                let form = new FormData();
                form.append("quiz_result_answer_id", state.question.quiz_result_answer_id);
                form.append("quiz_option_id", quiz_option_id);
                axios
                    .post($config['url'] + "api/v1/quiz/answer", form)
                    .then(function (response) {
                        commit('setQuestion', response.data);
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        REFRESH_SECONDS({commit}, seconds) {
            commit('setSecondsResult', seconds);
        },
    },
    mutations: {
        setSecondsResult (state, data) {
            state.result.seconds = data;
        },
        setQuiz (state, data) {
            state.quiz = data;
        },
        setInvite (state, data) {
            state.invite = data;
        },
        setInviteSave (state) {
            state.invite.count_attempts_used = 0;
        },
        countInvite (state) {
            state.invite.count_attempts_used++;
        },
        startQuiz (state, data) {
            state.result = data.result;
            state.result.question_index = 1;
            state.result.score = 0;
            state.result.seconds = 0;
            state.question = data.question;
            state.questions[state.result.question_index] = state.question;
        },
        reloadQuiz (state, data) {
            state.result = data.result;
            state.question = data.question;
            state.questions[state.result.question_index] = state.question;
        },
        setQuestion (state, data) {
            if(typeof data.question == 'undefined' || state.questions[state.result.question_index].quiz_result_answer_id != data.question.quiz_result_answer_id) {
                state.result.question_index++;
                let scenario = state.quiz.scenarios.find(scenario => scenario.id === state.result.quiz_scenario_id);
                if (state.result.question_index > scenario.count_questions) {
                    state.result.score = data.result.score;
                    state.result.seconds = data.result.seconds;
                }
                else {
                    state.question = data.question;
                    state.questions[state.result.question_index] = state.question;
                }
            }
        },
    },
})

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
})