import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import Main from "@/views/quiz/main";
import View from "@/views/quiz/view";
import ViewInvite from "@/views/quiz/view_invite";
import Error403 from "@/views/error/403";
import Error404 from "@/views/error/404";

const routes = [
  {
    path: "/",
    component: Main,
  },
  {
    path: "/view",
    component: {
      render: (h) => h("router-view"),
    },
    children: [
      {
        path: '/view/:id',
        component: View,
      },
      {
        path: '/invite/:id/:key',
        component: ViewInvite,
      },
    ]
  },
  {
    path: "/error/403",
    component: Error403
  },
  {
    path: "/error/404",
    component: Error404
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router
